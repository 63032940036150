'use strict'

/**
 * Object used to validate the new user.
 *
 * @author Andres Felipe Correa
 * @since 10/07/2020 10:04 AM
 * @version 1.0.0
 */
export default {
  companyName: {
    required: true,
    max: 50
  },
  nationality: {
    required: true
  },
  email: {
    required: true,
    email: true,
    max: 50
  },
  confirmEmail: {
    required: true,
    email: true,
    max: 50,
    confirmed: 'email'
  },
  userName: {
    required: true,
    max: 15
  },
  password: {
    required: true,
    min: 8,
    max: 50,
    password_format: true
  },
  confirm_password: {
    required: true,
    min: 8,
    max: 50,
    password_format: true,
    confirmed: 'password'
  }
}
