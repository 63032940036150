<template>
  <v-container>
    <v-row>
      <v-card-subtitle class="pt-5 col col-12 headline text-uppercase grey--text">
        {{ $t('new_user') }}
      </v-card-subtitle>
      <v-col
        cols="12"
        class="pt-1"
      >
        <v-divider class="pa-0 mb-4" />
      </v-col>
    </v-row>
    <v-row>
      <v-card-subtitle class="pt-2 col col-12 headline text-uppercase grey--text">
        {{ $t('basic_data') }}
      </v-card-subtitle>
      <v-col
        cols="12"
        class="pt-1"
      >
        <v-divider class="pa-0 mb-4" />
      </v-col>
    </v-row>
    <ValidationObserver
      ref="obs"
      v-slot="{ passes }"
    >
      <v-form
        @submit.prevent="passes(saveUser)"
      >
        <v-row>
          <v-col
            cols="4"
            class="py-0"
          >
            <app-text-field-with-validation
              id="businessName"
              v-model="model.businessUserDetailDTO.businessName"
              :rules="registerUserValidations.companyName"
              :label="$t('company_name').toLowerCase()"
              :label-input="$t('company_name')"
              name="businessName"
              type="text"
            />
          </v-col>
          <v-col
            cols="4"
            class="py-0"
          >
            <app-select-with-validation
              id="nationality"
              v-model="model.businessUserDetailDTO.nationality"
              :items="countries"
              :label="$t('nationality').toLowerCase()"
              :label-input="$t('nationality')"
              :rules="registerUserValidations.nationality"
              item-text="name"
              item-value="id"
              name="nationality"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="4"
            class="py-0"
          >
            <app-text-field-with-validation
              id="username"
              v-model="model.username"
              :rules="registerUserValidations.email"
              :label="$t('user_name').toLowerCase()"
              :label-input="$t('user_name')"
              name="username"
              type="text"
            />
          </v-col>
          <v-col
            cols="4"
            class="py-0"
          >
            <app-text-field-with-validation
              id="password"
              v-model="model.password"
              :rules="registerUserValidations.password"
              :label="$t('password').toLowerCase()"
              :label-input="$t('password')"
              name="password"
              type="password"
            />
          </v-col>
          <v-col
            cols="4"
            class="py-0"
          >
            <app-text-field-with-validation
              id="confirmPassword"
              :rules="registerUserValidations.confirm_password"
              :label="$t('password_confirm').toLowerCase()"
              :label-input="$t('password_confirm')"
              name="confirmPassword"
              type="password"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-card-subtitle class="pt-2 col col-12 headline text-uppercase grey--text">
            {{ $t('members') }}
          </v-card-subtitle>
          <v-col
            cols="12"
            class="pt-1"
          >
            <v-divider class="pa-0 mb-4" />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="4"
            class="py-0"
          >
            <v-text-field
              id="nameMember"
              v-model="member"
              label="Nombre integrante"
              name="userEmail"
              type="text"
              outlined
              rounded
            />
            <span
              v-if="model.businessUserDetailDTO.members.length == 0"
              class="v-messages v-messages__message error--text px-2"
            >
              {{ $t('input_member') }}
            </span>
          </v-col>
          <v-col
            cols="3"
            class="py-0"
          >
            <v-btn
              large
              color="primary"
              rounded
              :disabled="!member"
              @click="addMember"
            >
              {{ $t('add_member') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="py-0"
          >
            <v-chip
              v-for="(membe, index) in model.businessUserDetailDTO.members"
              :key="index"
              class="ma-2"
              close
              color="orange"
              label
              outlined
              @click:close="closeMember(index)"
            >
              {{ membe }}
            </v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-card-subtitle class="pt-6 col col-12 headline text-uppercase grey--text">
            {{ $t('project_title_section') }}
          </v-card-subtitle>
          <v-col
            cols="12"
            class="pt-1"
          >
            <v-divider class="pa-0 mb-2" />
          </v-col>
        </v-row>
        <v-col
          cols="12"
          class="py-0"
        >
          <v-checkbox
            v-for="project in projectsActive"
            :key="project.id"
            :label="project.name"
            color="orange"
            value=""
            hide-details
            @change="addProject(project)"
          />
          <span
            v-if="model.tenderDTOList.length == 0"
            class="v-messages v-messages__message error--text px-2"
          >
            {{ $t('add_tender') }}
          </span>
        </v-col>
        <v-row class="d-flex justify-end">
          <v-btn
            color="gray_color lighten-1 white--text"
            class="mt-12 mb-4 mr-4"
            @click="toReturn"
          >
            {{ $t('exit') }}
          </v-btn>
          <v-btn
            color="primary lighten-1 white--text"
            class="mt-12 mb-4 mr-4"
            type="submit"
            :disabled="!validateMemberAndTender"
          >
            {{ $t('save') }}
          </v-btn>
        </v-row>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
// import Vue from 'vue'
import registerUserValidations from './data/registerUserValidations'
import handleErrorMixin from '../../../../../mixins/handleErrorMixin'
import { URL_GET_COUNTRIES, URL_GET_TENDER, URL_CREATE_USER } from './data/registerUser'
import { ADD_COUNTER_REQUEST, SUBTRACT_COUNTER_REQUEST, SHOW_ALERT } from '@/store/mutations-types'
import { CONFLICT, BAD_REQUEST } from '@/shared/constants/httpStatus'

export default {
  name: 'RegisterUserNew',
  mixins: [handleErrorMixin],
  data () {
    return {
      registerUserValidations,
      countries: [],
      projectsActive: [],
      model: {
        username: '',
        email: null,
        password: '',
        businessUserDetailDTO: {
          businessName: '',
          nationality: '',
          members: []
        },
        tenderDTOList: [],
        noRobot: null
      },
      member: ''
    }
  },
  computed: {
    /**
     * This calculated property validates that members and projects are not empty.
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 13/07/2020
     */
    validateMemberAndTender () {
      const cantMember = this.model.businessUserDetailDTO.members.length
      const cantTender = this.model.tenderDTOList.length
      return (cantMember > 0 && cantTender > 0)
    }
  },
  watch: {
    /**
     * This watcher set value of username to email for the moment
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 03/08/2020
     */
    'model.username' (newVallue, oldValue) {
      this.model.email = newVallue
    }
  },
  created () {
    /**
     * This hook get the countries
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 13/07/2020
     */
    this.getCountries()
    /**
     * This hook get the projets in active status
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 13/07/2020
     */
    this.getProjectsActive()
  },
  methods: {
    /**
     * This method add a member to array with members
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 13/07/2020
     */
    addMember () {
      this.model.businessUserDetailDTO.members.push(this.member)
      this.member = ''
    },
    /**
     * This method close a member on the checklist of members
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 13/07/2020
     */
    closeMember (index) {
      this.model.businessUserDetailDTO.members.splice(index, 1)
    },
    /**
     * This method redirect to the home page
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 13/07/2020
     */
    toReturn () {
      this.$router.push({ name: 'Authentication' })
    },
    /**
     * This method run endpoint to get the countries list
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 13/07/2020
     */
    getCountries () {
      this.axios.get(URL_GET_COUNTRIES)
        .then((response) => {
          this.countries = response.data
        }).catch((error) => {
          this.$_handleErrorMixin_generateError(error)
        })
    },
    /**
     * This method run endpoint to get the projects in active status
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 13/07/2020
     */
    getProjectsActive () {
      this.axios.get(URL_GET_TENDER)
        .then((response) => {
          this.projectsActive = response.data
        }).catch((error) => {
          this.$_handleErrorMixin_generateError(error)
        })
    },
    /**
     * This method add a project to array projects
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 13/07/2020
     */
    addProject (project) {
      if (this.model.tenderDTOList.includes(project)) {
        this.model.tenderDTOList.splice(this.model.tenderDTOList.indexOf(project), 1)
      } else {
        this.model.tenderDTOList.push(project)
      }
    },
    /**
     * This method save the new user
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 14/07/2020
     */
    saveUser () {
      this.$store.commit(ADD_COUNTER_REQUEST)
      this.axios.post(URL_CREATE_USER, this.model).then((response) => {
        this.$store.commit(SHOW_ALERT, {
          type: 'success',
          text: this.$t('save_success'),
          show: true
        })
        this.$router.push({ name: 'setCode', params: { userCreated: response.data } })
      }).catch((error) => {
        if (error.response.status === CONFLICT) {
          this.$store.commit(SHOW_ALERT, {
            type: 'warning',
            text: this.$t('wrong_username'),
            show: true
          })
          this.model.username = ''
          return
        }
        if (error.response.status === BAD_REQUEST) {
          this.$store.commit(SHOW_ALERT, {
            type: 'warning',
            text: error.response.data.errorMessage,
            show: true
          })
          return
        }
        this.$_handleErrorMixin_generateError(error)
      }).finally(() => {
        this.$store.commit(SUBTRACT_COUNTER_REQUEST)
      })
    }
  }
}
// document.addEventListener('contextmenu', event => event.preventDefault())
</script>
<style>
  .v-btn.primary > span {
  color: #424242;
}
</style>
<i18n src="./data/i18nMessage.json" />
